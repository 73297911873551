<template>
  <div class="bg">
    <div v-if="step === 0" class="row  desktop">
      <div class="col-md-5 retangulo-desktop">
        <div class="bem-vindo-desktop">
          <p class="texto-apresentacao">Compre ou venda sua bike com a procedência </p>
          <p class="texto-apresentacao-bold">Bike registrada</p>
        </div>
      </div>
      <div class="col-md-7 col-sm-12">
        <section style="margin: 0;">
          <div class="logo-bike">
            <b-img width="190px" src="./img/logo-bike-preta.png"></b-img>

          </div>
          <div class="row center">
            <div class="col-sm-12 col-md-6 ml-15">

              <p class="bold">Se você está recebendo esse link, provavelmente recebeu uma bike.</p>
              <p class="sub-content">Para confirmar a transferência de propriedade, confirme seu CPF.</p>

              <ValidationObserver ref="formObserver">
                <form>
                  <div class="form-group mb-3 mt-5">
                    <ValidationProvider mode="passive" name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                      <input :style="cpf ? 'color: #454545;background:#fff;' : ''
                        " type="tel" maxlength="20" class="form-control1" :class="classes" v-model="cpf"
                        @focus="clearErrors" v-mask="'###.###.###-##'" autocomplete="nope"
                        placeholder="Digite o seu CPF" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div>
                    <button class="bike-btn" :disabled="loading" @click.prevent="auth">
                      <span v-if="!loading" class="text">Confirmar CPF</span>
                      <span v-else class="text">Aguarde</span>
                    </button>
                  </div>
                </form>
              </ValidationObserver>

            </div>
          </div>
        </section>
      </div>

    </div>

    <div v-if="step === 1" class="container">
      <div class="logo-bike-direita">
        <b-img class="mt-5" width="190px" src="./img/logo-bike-preta.png"></b-img>
      </div>
      <div class="row mt-10">
        <div v-if="!novoCliente" class="col-sm-12 col-md-6">
          <p class="title">Olá! {{ dados.nomeDestinatario }},</p>
          <p>Verificamos que você possui um cadastro ativo, confira os dados para vincular sua bike a
            sua conta.</p>
        </div>
        <div v-else class="col-sm-12 col-md-6">
          <p class="title">Olá! Seja Bem vindo,</p>
          <p>Verificamos que você ainda não possui um cadastro, confira os dados para vincular sua bike
            a sua nova conta.</p>
        </div>

      </div>
      <div class="row dashed content-center mt-5">
        <div class="col-sm-12 col-md-2 ">
          <span class="label">Marca</span>
          <span class="content">{{ dados.marcaBike }}</span>
        </div>
        <div class="col-sm-12 col-md-2 border-left">
          <span class="label">Número de série:</span>
          <span class="content">{{ dados.numeroSerieBike }}</span>
        </div>
        <div class="col-sm-12 col-md-2 border-left">
          <span class="label">Cor:</span>
          <span class="content">{{ dados.corBike }}</span>
        </div>
        <div class="col-sm-12 col-md-2 border-left">
          <span class="label">Modelo:</span>
          <span class="content">{{ dados.modeloBike }}</span>
        </div>

      </div>
      <div class="container dados-cliente mt-5">
        <p class="dados">Dados do novo proprietario</p>
        <div class="row">

          <div class="col-sm-12 col-md-4">
            <ValidationObserver ref="formObserver1">
              <form>
                <div class="form-group">
                  <ValidationProvider mode="passive" name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                    <input type="tel" maxlength="20" class="input-text" :class="classes" v-model="dados.cpfDestinatario"
                      @focus="clearErrors1" v-mask="'###.###.###-##'" autocomplete="nope" />
                    <span class="floating-label">Informe o CPF do novo proprietário</span>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-sm-12 col-md-4">
            <ValidationObserver ref="formObserver2">
              <form>
                <div class="form-group">
                  <ValidationProvider mode="passive" name="nome" rules="required" v-slot="{ errors, classes }">
                    <input type="text" class="input-text" :class="classes" v-model="dados.nomeDestinatario"
                      @focus="clearErrors2" autocomplete="nope" />
                    <span class="floating-label">Informe o Nome do novo proprietário</span>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>

          </div>
          <div class="col-sm-12 col-md-4">
            <ValidationObserver ref="formObserver3">
              <form>
                <div class="form-group">
                  <ValidationProvider mode="passive" name="celular" rules="required" v-slot="{ errors, classes }">
                    <input type="tel" maxlength="20" class="input-text" :class="classes"
                      v-model="dados.celularDestinatario" @focus="clearErrors3" v-mask="'(##) #####-####'"
                      autocomplete="nope" />
                    <span class="floating-label">Informe o Celular do novo proprietário</span>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>

          </div>
          <div class="col-sm-12 col-md-4">
            <ValidationObserver ref="formObserver4">
              <form>
                <div class="form-group">
                  <ValidationProvider mode="passive" name="email" rules="required|email" v-slot="{ errors, classes }">
                    <input type="text" class="input-text" :class="classes" v-model="dados.emailDestinatario"
                      @focus="clearErrors4" autocomplete="nope" />
                    <span class="floating-label">Informe o E-mail do novo proprietário</span>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <ValidationObserver ref="formObserver5">

                <form>
                  <ValidationProvider name="estado" mode="passive" rules="required" v-slot="{ errors, classes }">
                    <span class="floating-label-select">Informe o estado do novo proprietário</span>
                    <select @focus="clearErrors5" class="input-text-select" v-model="dados.estado" :class="classes">
                      <!-- <option :value="undefined">Selecione sua UF</option> -->
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AM">Amazonas</option>
                      <option value="AP">Amapá</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="MS">Mato Grosso do sul</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="PR">Paraná</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RO">Roraima</option>
                      <option value="RR">Rondônia</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SE">Sergipe</option>
                      <option value="SP">São Paulo</option>
                      <option value="TO">Tocantins</option>
                    </select>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>

                </form>
              </ValidationObserver>

            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div class="form-group">
              <ValidationObserver ref="formObserver6">
                <form>
                  <ValidationProvider mode="passive" name="nascimento" rules="required|data_nascimento|data_maior_idade"
                    v-slot="{ errors, classes }">
                    <input @focus="clearErrors6" type="tel" class="input-text" :class="classes"
                      v-model="dados.dataNascimento" v-mask="'##/##/####'" autocomplete="nope" />
                    <span class="floating-label">Informe o nascimento do novo proprietário</span>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>


      <div class="align-right mt-5">
        <button v-if="!novoCliente" class="bike-btn-direita" :disabled="loading" @click.prevent="vincular">
          <span v-if="!loading" class="text">Receber bike</span>
          <span v-else class="text">Aguarde</span>
        </button>
        <button v-else class="bike-btn-direita" :disabled="loading" @click.prevent="vincular">
          <span v-if="!loading" class="text">Criar conta e vincular bike</span>
          <span v-else class="text">Aguarde</span>
        </button>
      </div>
    </div>

    <div v-if="step === 2" class="row  desktop">
      <div class="col-md-5 retangulo-desktop"></div>
      <div class="col-md-7 col-sm-12">
        <section style="margin: 0;">
          <div class="logo-bike">
            <b-img width="190px" src="./img/logo-bike-preta.png"></b-img>

          </div>
          <div class="row center">
            <div class="col-sm-12 col-md-6 ml-15">

              <p class="bold">Sua conta foi criada com sucesso</p>
              <p class="sub-content">Agora precisamos que você crie uma senha com no mínimo 6 dígitos para a acessar a
                conta Bike Registrada
              </p>

              <ValidationObserver ref="senhaObserver">
                <form>
                  <div class="form-group mb-3 mt-5">
                    <ValidationProvider mode="passive" name="senha" rules="required" v-slot="{ errors, classes }">
                      <input type="password" maxlength="20" class="form-control1" :class="classes" v-model="senha"
                        @focus="clearSenha" autocomplete="nope" placeholder="Digite sua senha" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group mb-3">
                    <ValidationProvider mode="passive" name="confirmar senha" rules="required"
                      v-slot="{ errors, classes }">
                      <input type="password" maxlength="20" class="form-control1" :class="classes"
                        v-model="confirmarSenha" @focus="clearSenha" autocomplete="nope"
                        placeholder="Confirme sua senha" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                      <span class="invalid"
                        v-if="senha != confirmarSenha && confirmarSenha != null && confirmarSenha != ''">As senhas
                        precisam
                        ser iguais</span>
                    </ValidationProvider>
                  </div>
                  <div>
                    <button class="bike-btn" :disabled="loading" @click.prevent="pass">
                      <span v-if="!loading" class="text">Avançar</span>
                      <span v-else class="text">Aguarde</span>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div v-if="step === 3" class="row  desktop">
      <div class="col-md-5 retangulo-desktop"></div>
      <div class="col-md-7 col-sm-12">
        <section style="margin: 0;">
          <div class="logo-bike mt-5">
            <b-img width="190px" src="./img/logo-bike-preta.png"></b-img>

          </div>
          <div class="row center">
            <div class="col-sm-12 col-md-6 ml-15">

              <p class="bold fs-2 mb-5">Bike vinculada com sucesso!</p>
              <p class="sub-content">Baixe agora mesmo o app Bike Registrada e acesse sua conta.</p>

              <p>Disponível para iOS e Android</p>
              <div>
                <a target="_blank" href="https://apps.apple.com/br/app/bike-registrada/id1661678422?platform=iphone">
                  <b-img class="img-loja" src="/img/loja-apple.png"></b-img>
                </a>

                <a target="_blank"
                  href="https://play.google.com/store/apps/details?id=br.com.seguro.bikeregistrada&amp;hl=pt_BR&amp;gl=US">
                  <b-img class="img-loja ml-2" src="/img/loja-google.png"></b-img>
                </a>
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
    <Loading :black="true" :criandoSeguro="true" v-if="loading" />
    <ModalErroTransferencia :show="erro" :title="erroTitle" :message="erroMessage" @hide="hideErro" />
  </div>
</template>
<script>
import axios from "axios";
import config from "../config";
import moment from "moment";
import Loading from "../components/Usuario/Loading";
export default {
  components: {
    Loading,
    ModalErroTransferencia: () => import("../components/Modal/ModalErroTransferencia"),
  },
  data() {
    return {
      config,
      cpf: undefined,
      dados: {
        marcaBike: undefined,
        numeroSerieBike: undefined,
        corBike: undefined,
        modeloBike: undefined,
        cpfDestinatario: undefined,
        nomeDestinatario: undefined,
        celularDestinatario: undefined,
        emailDestinatario: undefined,
        estado: undefined,
        nascimento: undefined,
      },
      step: undefined,
      loading: false,
      novoCliente: false,
      senha: undefined,
      confirmarSenha: undefined,
      erro: false,
      erroMessage: undefined,
      erroTitle: undefined
    };
  },
  created() {
    window.localStorage.clear();
    this.step = 0;
  },

  methods: {
    hideErro() {
      this.erro = false;
    },
    clearErrors() {
      this.$nextTick(() => {
        this.$refs.formObserver.reset(); // Reset validation errors
      });
    },
    clearErrors1() {
      this.$nextTick(() => {
        this.$refs.formObserver1.reset(); // Reset validation errors
      });
    },
    clearErrors2() {
      this.$nextTick(() => {
        this.$refs.formObserver2.reset(); // Reset validation errors
      });
    },
    clearErrors3() {
      this.$nextTick(() => {
        this.$refs.formObserver3.reset(); // Reset validation errors
      });
    },
    clearErrors4() {
      this.$nextTick(() => {
        this.$refs.formObserver4.reset(); // Reset validation errors
      });
    },
    clearErrors5() {
      this.$nextTick(() => {
        this.$refs.formObserver5.reset(); // Reset validation errors
      });
    },
    clearErrors6() {
      this.$nextTick(() => {
        this.$refs.formObserver6.reset(); // Reset validation errors
      });
    },
    clearSenha() {
      this.$nextTick(() => {
        this.$refs.senhaObserver.reset(); // Reset validation errors
      });
    },
    async pass() {
      let valid = await this.$refs.senhaObserver.validate();
      var senhaInvalida = this.senha != this.confirmarSenha;

      if (!valid || senhaInvalida) return;

      try {

        this.loading = true;
        await axios.post(`${config.API_URLV2}/cliente/senha`, {
          idCliente: this.idCliente,
          senha: this.senha,
        });

        this.loading = false;

        this.step = 3;

      } catch (error) {
        this.loading = false;
        this.erroTitle = error?.response?.data?.error == null ? "Não foi possivel realizar a operação" : "Problema na troca de senha";
        this.erroMessage = error?.response?.data?.error == null ? "Tente novamente mais tarde ou contate o suporte." : error?.response?.data?.error;
        this.erro = true;

      }
      this.loading = false;
    },
    async vincular() {
      let valid1 = await this.$refs.formObserver1.validate();
      let valid2 = await this.$refs.formObserver2.validate();
      let valid3 = await this.$refs.formObserver3.validate();
      let valid4 = await this.$refs.formObserver4.validate();
      let valid5 = await this.$refs.formObserver5.validate();
      let valid6 = await this.$refs.formObserver6.validate();

      if (!valid1 || !valid2 || !valid3 || !valid4 || !valid5 || !valid6) return;

      try {

        this.loading = true;
        var response = await axios.post(`${config.API_URLV2}/transferenciabike/vincular`, {
          idRegistro: this.dados.idRegistro,
          nome: this.dados.nomeDestinatario,
          cpf: this.dados.cpfDestinatario,
          celular: this.dados.celularDestinatario,
          email: this.dados.emailDestinatario,
          estado: this.dados.estado,
          nascimento: this.dados.dataNascimento
            ? moment(this.dados.dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        });

        this.idCliente = response.data.idCliente;

        this.loading = false;

        this.step = this.novoCliente ? 2 : 3;

      } catch (error) {
        this.loading = false;
        this.erroTitle = error?.response?.data?.error == null ? "Não foi possivel realizar a operação" : "Confira seu CPF !";
        this.erroMessage = error?.response?.data?.error == null ? "Tente novamente mais tarde ou contate o suporte." : error?.response?.data?.error;
        this.erro = true;
      }
      this.loading = false;
    },
    async auth() {
      let valid = await this.$refs.formObserver.validate();
      if (!valid) return;
      try {

        this.loading = true;
        var response = await axios.get(
          `${config.API_URLV2}/transferenciabike/${this.cpf}`
        );

        this.dados = response.data;
        this.dados.numeroSerieBike = this.dados.numeroSerieBike.replaceAll(";", "-");
        this.dados.dataNascimento = moment(this.dados.dataNascimento, "YYYY-MM-DD").format("DD/MM/YYYY");
        this.novoCliente = this.dados.emailDestinatario == null;
        this.loading = false;
        this.step++;

      } catch (error) {
        this.loading = false;
        this.erroTitle = error?.response?.data?.error == null ? "Não foi possivel realizar a operação" : "Confira seu CPF !";
        this.erroMessage = error?.response?.data?.error == null ? "Tente novamente mais tarde ou contate o suporte." : error?.response?.data?.error;
        this.erro = true;
      }

    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open%20Sans");

.mb-5 {
  margin-bottom: 5vw !important;
}

.input-text-select {
  width: 100%;
  border-radius: 12px;
  background: #F7F7F7;
  height: 65px;
  padding: 0px 12px;
  border: none;
  color: #263238;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.title {
  color: #263238;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-title {
  color: #263238;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dados {
  color: #263238;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.img-bike {
  width: 81.6px;
  height: 49.067px;
  border-radius: 6px;
  border: none;
}

.img-loja {
  width: 35%;
}

.ml-2 {
  margin-left: 2%;
}

.label {
  display: block;
  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content {
  color: #263238;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.label-cliente {
  color: #757575;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-cliente {
  color: #263238;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.align-right {
  text-align: right;
}

.dados-cliente {
  padding: 12px;
  gap: 8px;
  border-radius: 12px;
  background-color: white !important;
}

.dashed {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  border: 2px dashed #4FA935;
  background-color: white !important;
}

.ml-15 {
  margin-left: 15%;
}

.mt-15 {
  margin-top: 15% !important;
}

.mt-10 {
  margin-top: 10% !important;
}


.form-control1 {
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  color: #9a9a9a;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 16px;
  border-radius: 12px;
  background: #FFF;
}

input:focus~.floating-label,
input:not(:focus):valid~.floating-label {
  top: 8px;
  bottom: 10px;
  left: 30px;
  font-size: 12px;
}

.input-text {
  width: 100%;
  border-radius: 12px;
  background: #F7F7F7;
  height: 65px;
  padding: 0px 16px;
  border: none;
  color: #263238;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.floating-label {
  color: #757575;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 18px;
  transition: 0.2s ease all;
}

.floating-label-select {
  color: #757575;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  pointer-events: none;
  left: 30px;
  top: 10px;
  transition: 0.2s ease all;
}

.bike-btn {
  display: block;
  padding: 10px;
  width: 100%;
  height: 64px;
  border: 1px solid #263238;
  border-radius: 12px;
  background: #263238;
  color: #fff;
}

.bike-btn-direita {
  /* display: block; */
  padding: 10px;
  width: 25%;
  height: 64px;
  border: 1px solid #263238;
  border-radius: 12px;
  background: #263238;
  color: #fff;
}

.logo-bike {
  margin-top: 5%;
  margin-left: 15%;
  left: 44%;
  right: 57%;
}

.logo-bike-direita {
  text-align: right;
}

.center {
  display: flex;
  align-items: center;
  height: 70vh;
}

.content-center {
  justify-content: space-around;
}

.retangulo-desktop {
  background: url("/img/bicicleta-preta-transferencia.png") no-repeat;
  background-size: cover;
}

.bem-vindo-desktop {
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 54px;
  width: 70%;
}

.texto-apresentacao {
  color: #FFF;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.texto-apresentacao-bold {
  color: #FFF;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-weight: 700;
}

.bold {
  font-size: 1.2vw;
  font-weight: 900;
}

.fs-2 {
  font-size: 2.2vw;
}

.row {
  margin: 0;
}

.desktop {
  height: 100vh;
}

.bg {
  background-color: #f7f7f7;
  font-family: Open Sans !important;
}

*:focus {
  outline: 0;
}

.sub-content {
  font-size: 15px;
}

@media (max-width: 768px) {
  .retangulo-desktop {
    display: none;
  }

  .ml-15 {
    margin-left: 0px !important;
  }

  .logo-bike[data-v-26feec1e] {
    text-align: center;
    margin-top: 5%;
    margin-left: 0px;
  }

  .center {
    text-align: center;
  }

  .bold {
    font-size: 8vw;
  }

  .border-left {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-left: none !important;
    padding-top: 10px;
  }

  .bike-btn-direita {
    width: 100%;
  }

  .sub-content {
    font-size: 20px;
  }

  .img-loja {
    margin-left: 5%;
    width: 42%;
  }

  .logo-bike-direita {
    text-align: center;
  }
}</style>
